import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
  VARIANTS_TABLE_CONTRACTS_HP_OPTIONS,
  VARIANTS_TABLE_NEW_CANCEL_CHANGE_OPTIONS,
  VARIANTS_TABLE_PROFILES_OPTIONS,
} from '../../enums/enumTablesVariants'
import { TABLE_TYPES } from '../../enums/enumTableTypes'

interface iGlobalState {
  contextMenuData: any
  pageLimits: { [name: string]: { page: number; limit: number } }
  sorts: { [name: string]: { field: string; order: boolean } }
  filters: { [name: string]: { [name: string]: string } }
  variants: { [name: string]: string }
  mobileMenuOpen: boolean
  reloadFlag: number
}

const createAllTableTypesAsDefault = (val: any) =>
  Object.values(TABLE_TYPES).reduce((prev, cur) => ({ ...prev, [cur]: val }), {})

const defTableVariants = {
  [TABLE_TYPES.profiles]: VARIANTS_TABLE_PROFILES_OPTIONS[0].value,
  [TABLE_TYPES.contractsHp]: VARIANTS_TABLE_CONTRACTS_HP_OPTIONS[0].value,
  [TABLE_TYPES.newCancel]: VARIANTS_TABLE_NEW_CANCEL_CHANGE_OPTIONS[0].value,
}

const defTableSorts = {
  ...createAllTableTypesAsDefault({ field: 'createdAt', order: false }),
  [TABLE_TYPES.contracts]: { field: 'data.datumUverejneniVvz', order: false },
  [TABLE_TYPES.profiles]: { field: 'data.datumUverejneniVvz', order: false },
  [TABLE_TYPES.newAll]: { field: 'data.datumUverejneniVvz', order: false },
  [TABLE_TYPES.newWins]: { field: 'data.datumUverejneniVvz', order: false },
  [TABLE_TYPES.newCancel]: { field: 'data.datumUverejneniVvz', order: false },
  [TABLE_TYPES.newContracts]: { field: 'data.datumUverejneniVvz', order: false },
  [TABLE_TYPES.myForms]: { field: 'data.datumUverejneniVvz', order: false },
  [TABLE_TYPES.contractsHp]: { field: 'data.lhutaNabidkyZadosti', order: true },
  [TABLE_TYPES.stornoContracts]: { field: 'data.datumUverejneniVvz', order: false },
}

const defTablePageLimits = {
  ...createAllTableTypesAsDefault({ page: 1, limit: 10 }),
  [TABLE_TYPES.profileForms]: { page: 1, limit: 100 },
  [TABLE_TYPES.contractForms]: { page: 1, limit: 100 },
  [TABLE_TYPES.profileFormsRepair]: { page: 1, limit: 100 },
}

const slice = createSlice({
  name: 'global',
  initialState: {
    contextMenuData: {},
    pageLimits: defTablePageLimits,
    sorts: defTableSorts,
    filters: createAllTableTypesAsDefault({}),
    variants: defTableVariants,
    mobileMenuOpen: false,
    reloadFlag: 0,
  } as iGlobalState,
  reducers: {
    setPage(state, action: PayloadAction<{ name: string; page: number }>) {
      const { page, name } = action.payload
      state.pageLimits[name].page = page
    },
    setLimit(state, action: PayloadAction<{ name: string; limit: number }>) {
      const { limit, name } = action.payload
      state.pageLimits[name].limit = limit
      state.pageLimits[name].page = 1
    },
    setFilters(state, action: PayloadAction<{ name: string; filters: any }>) {
      const { name, filters } = action.payload
      state.filters[name] = filters
      state.pageLimits[name].page = 1
    },
    resetFilters(state, action: PayloadAction<string>) {
      const name = action.payload
      state.filters[name] = {}
      state.pageLimits[name].page = 1
      state.sorts[name] = (defTableSorts as any)[name]
    },
    setSort(state, action: PayloadAction<{ name: string; field: string }>) {
      const { field, name } = action.payload
      if (state.sorts[name].field === field) {
        state.sorts[name].order = !state.sorts[name].order
      } else {
        state.sorts[name].field = field
      }
    },
    setVariant(state, action: PayloadAction<{ name: string; variant: string }>) {
      const { variant, name } = action.payload
      state.variants[name] = variant
      state.pageLimits[name].page = 1
    },
    setMobileMenuOpen(state, action: PayloadAction<boolean>) {
      state.mobileMenuOpen = action.payload
    },
    reloadData(state) {
      state.reloadFlag++
    },
  },
})

export const { setPage, setLimit, setSort, setFilters, resetFilters, setVariant, setMobileMenuOpen, reloadData } =
  slice.actions
export default slice.reducer
