import React from 'react'
import { useTranslation } from 'react-i18next'
import ButtonGov from '../../components/Btns/ButtonGov'
import { useFilterContext } from '../../context/FilterContext'

interface iFilterForm {
  clearOrder?: boolean
  className?: string
  children: React.ReactNode
}

const FilterFormWrap = ({ children, clearOrder, className }: iFilterForm) => {
  const { t } = useTranslation()
  const { isEmpty, applyFilter, clearFilter, setSort } = useFilterContext()

  const doFilter = () => {
    if (clearOrder) {
      setSort('')
    }
    applyFilter()
  }

  const resetFilter = () => {
    clearFilter()
  }

  const handleSubmit = (event: any) => {
    event.preventDefault()
    doFilter()
  }

  return (
    <form onSubmit={handleSubmit} className={className}>
      {children}
      <p className='u-pt--20'>
        <ButtonGov
          type='submit'
          variant='primary'
          className='u-me--20'
          // onClick={() => doFilter()}
          disabled={isEmpty}
        >
          {t('global.hledat', 'Hledat')}
        </ButtonGov>

        <ButtonGov variant='primary-outlined' onClick={() => resetFilter()} disabled={isEmpty}>
          {t('global.vymazat_filtr', ' Vymazat filtr')}
        </ButtonGov>
      </p>
    </form>
  )
}

export default FilterFormWrap
