import React from 'react'
import { useSelector } from 'react-redux'
import { FORM_TYPE_REQ_V2 } from '../../enums/enumFormTypeV2'
import { selectFormTreeIsBig } from '../../redux/formTree/formTreeSelectors'
import {
  selectSchemaFull,
  selectSchemaFullReq,
  selectSubmForm,
  selectSubmMainInfo,
  selectSubmReq,
  selectSubmSchemasFormId,
  selectSubmSchemasReqId,
} from '../../redux/submissions/selectors'
import { FormRenderContexProvider } from '../context/RenderFormContext'
import { FormFilesRenderContexProvider } from '../context/RenderFormFilesContext'
import RenderForm from '../renders/RenderForm'

interface iBuilder {
  isRequest?: boolean
  theme?: string
}

const FormBaseRedux = ({ isRequest, theme }: iBuilder) => {
  const formSchema = useSelector(isRequest ? selectSchemaFullReq : selectSchemaFull)
  const submission = useSelector(isRequest ? selectSubmReq : selectSubmForm)
  const formSchemaId = useSelector(isRequest ? selectSubmSchemasReqId : selectSubmSchemasFormId)

  const { formType } = useSelector(selectSubmMainInfo)
  const isBigFormTree = useSelector(selectFormTreeIsBig)

  // useEffect(() => {
  //   const prevTitle = document.title
  //   if (data) {
  //     document.title = 'VVZ formulář :: ' + data.name
  //   }
  //   return () => {
  //     document.title = prevTitle
  //   }
  // }, [data])

  const dataSchemas = formSchema?.properties
  const uiSchemas = formSchema?.layout
  const schemaId = formSchemaId

  const formTitle = formSchema?.form?.title
  const formDesc = formSchema?.form?.description

  if (!dataSchemas || !uiSchemas) {
    return null
  }

  return (
    <section>
      {formTitle && <h1>{formTitle}</h1>}
      {formDesc && <div>{formDesc}</div>}
      <FormRenderContexProvider
        dataSchemas={dataSchemas}
        uiSchemas={uiSchemas}
        submission={submission}
        formSchemaId={schemaId}
        formType={isRequest ? FORM_TYPE_REQ_V2 : formType}
        liveValidation={!isBigFormTree}
        isReq={isRequest}
        theme={theme}
      >
        <FormFilesRenderContexProvider>
          <RenderForm key={submission.id} />
        </FormFilesRenderContexProvider>
      </FormRenderContexProvider>
    </section>
  )
}

export default FormBaseRedux
