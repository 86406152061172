import React, { ButtonHTMLAttributes } from 'react'

export interface iBtnGov extends ButtonHTMLAttributes<any> {
  variant?: 'primary' | 'primary-outlined'
  size?: 'small' | 'large'
  inversed?: boolean
  loading?: boolean
}

const ButtonGov = ({ type = 'button', variant, size, inversed, children, className, loading, disabled, ...others }: iBtnGov) => {
  const classes = [className]
  if (variant) {
    classes.push('gov-button--' + variant)
  }
  if (size) {
    classes.push('gov-button--' + size)
  }
  if (inversed) {
    classes.push('gov-button--inversed')
  }

  return (
    <button type={type} className={classes.join(' ')} disabled={disabled || loading} {...others}>
      {loading && (
        <div className='sg-loaders'>
          <div className='sg-loaders__item'>
            <div className={'gov-loader ' + (inversed ? '' : 'gov-loader--inversed')}>
              <span>&nbsp;</span>
            </div>
          </div>
        </div>
      )}
      {children}
    </button>
  )
}

export default React.memo(ButtonGov)
