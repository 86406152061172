import React from 'react'
import { Link } from 'react-scroll'
import { setForceOpenToSection } from '../../redux/formTree/formTreeSlice'
import { useAppDispatch } from '../../redux/store'
import { iFormTree } from '../utils/createFormTree'
import { scrollToPath } from '../utils/scrollToPath'

interface iFormNavigationLink {
  formTree: iFormTree
}

export const FormNavigationLink = ({ formTree }: iFormNavigationLink) => {
  const dispath = useAppDispatch()

  const { to, text, level, errorsCount } = formTree

  if (!to || !text) {
    return null
  }
  const click = () => {
    dispath(setForceOpenToSection(to))
    setTimeout(() => {
      scrollToPath(to) // custom scroll - kvuli cekani na otevreni sekci
      dispath(setForceOpenToSection(''))
    }, 100)
  }

  return (
    <Link
      activeClass='active'
      className={'gov-link gov-link--standalone'}
      onClick={click}
      to={to}
      spy={true}
      smooth={true}
      isDynamic={true}
      // onSetActive={() => setActive(true)}
      // onSetInactive={) => setActive(false)}
      // onSetActive={() => console.log('active', text)}
      // onSetInactive={() => console.log('deactive', text)}
      offset={-20 * (level || 0)}
      duration={50}
    >
      <span className={'nav-link-text'}>{text}</span>
      {errorsCount ? <small className='err-count-nav'>{errorsCount}</small> : ''}
    </Link>
  )
}
