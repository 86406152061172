import React from 'react'
import { useSelector } from 'react-redux'
import { selectSubmMainInfo } from '../../redux/submissions/selectors'
import { formReqToMetodic, formTypeToMetodic } from '../../utils/formTypeToMetodic'
import FormHeaderAdminInfo from './FormHeaderAdminInfo'
import FormHeaderInfo from './FormHeaderInfo'

const FormHeader = () => {
  const { isDraft, formType } = useSelector(selectSubmMainInfo)
  return (
    <div className='custom-detail u-mb--20'>
      <FormHeaderAdminInfo />
      <div className='custom-detail__top'>
        <FormHeaderInfo />
        {isDraft && (
          <div className='custom-detail__side'>
            <ul>
              <li>
                <a href={formTypeToMetodic(formType)} target='_blank' rel='noopener noreferrer'>
                  Metodické pokyny pro vyplnění formuláře
                </a>
              </li>
              <li>
                <a href={formReqToMetodic()} target='_blank' rel='noopener noreferrer'>
                  Metodické pokyny pro vyplnění Žádosti
                </a>
              </li>
              <li>
                <a href='https://isvz.nipez.cz/ciselniky' target='_blank' rel='noopener noreferrer'>
                  Číselníky a klasifikace
                </a>
              </li>
            </ul>
          </div>
        )}
      </div>
      <div className='custom-detail__bottom'>
        {isDraft && (
          <div className='custom-detail__alert'>
            <blockquote>
              Doporučujeme přihlášeným uživatelům, aby si vyplňovaná data průběžně ukládali, aby nedošlo ke ztrátě
              vyplněných dat.
            </blockquote>
          </div>
        )}
        <div className='custom-actions'>
          <div className='custom-actions__item'>
            {/*<p>*/}
            {/*  <button type='submit' className='gov-button gov-button--primary-outlined'>*/}
            {/*    Tisk TXT*/}
            {/*  </button>*/}
            {/*</p>*/}
            {/*<p>*/}
            {/*  <button type='submit' className='gov-button gov-button--primary-outlined'>*/}
            {/*    Tisk PDF*/}
            {/*  </button>*/}
            {/*</p>*/}
          </div>
        </div>
      </div>
    </div>
  )
}

export default FormHeader
