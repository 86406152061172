import React from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
import { LINKS } from '../App/LINKS'
import BreadcrumbApp from '../BreadcrumbApp/BreadcrumbApp'

interface iPageWrap {
  title?: string
  subtitle?: string
  subElement?: React.ReactNode
  breadcrumbsItems?: { link?: string; label: string }[]
  fullWidth?: boolean
  children?: React.ReactNode
}

const PageWrap = ({ title, subtitle, breadcrumbsItems, fullWidth, subElement, children }: iPageWrap) => {
  const { t } = useTranslation()

  // jen stranky ve kterych jsou zanorene dalsi
  const LINK_TO_TITLE = {
    [LINKS.vyhledatFormular]: t('title.vyhledat_form', 'Vyhledat formulář'),
    [LINKS.mojeVyhlaseni]: t('title.seznam_mych_formularu', 'Moje vyhlášení'),
    [LINKS.napoveda]: t('title.napoveda_dokumentace', 'Nápověda a dokumentace'),
    [LINKS.podatFormular]: t('title.podat_form', 'Podat formulář'),
    [LINKS.vyhledatFormular]: t('title.vyhledat_form', 'Vyhledat formulář'),
    [LINKS.aktuality]: t('news.aktuality_informace', 'Aktuality a informace'),
  }

  const SKIP_LINKS = [LINKS.formulareZakazky, LINKS.formulareProfilu]

  const location = useLocation()

  if (!breadcrumbsItems) {
    breadcrumbsItems = []
    const parts = location.pathname.split('/').filter((p) => p && p !== LINKS.informace.slice(1))
    parts.forEach((part, index) => {
      if (index === parts.length - 1) {
        const label = title || part
        breadcrumbsItems?.push({ label: label })
      } else {
        const link = '/' + parts.slice(0, index + 1).join('/') // poskladani z rootu
        if (!SKIP_LINKS.includes(link)) {
          const label = LINK_TO_TITLE[link] || part
          breadcrumbsItems?.push({ label: label, link: link })
        }
      }
    })
  }

  return (
    <div className='gov-container gov-container--wide gov-container--special overflowVisible'>
      <div
        className={
          'gov-container__content container_bg_white overflowVisible' + (fullWidth ? ' container__fullwidth' : '')
        }
      >
        <BreadcrumbApp items={breadcrumbsItems} />
        <div className='u-mb--60'>
          {subElement && subElement}
          {title && <h1 className='u-mb--unset'>{title}</h1>}
          {subtitle && <h3>{subtitle}</h3>}
        </div>
        {children}
      </div>
    </div>
  )
}

export default PageWrap
