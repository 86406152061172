import React from 'react'
import { useTranslation } from 'react-i18next'
import FilterFormWrap from '../components/FilterFormWrap'
import SelectFilterInput from '../components/filterInputs/SelectFilterInput'
import TextFilterInput from '../components/filterInputs/TextFilterInput'
import { optionsZneplatnen } from '../TableContracts/FilterContracts'

const FilterProfiles = () => {
  const { t } = useTranslation()
  return (
    <FilterFormWrap className='custom-form u-mb--50'>
      <h2 className='custom-form__title gov-title--delta'>
        {t('filterProfiles.vyhledavani_profilu', 'Vyhledávání profilů')}
      </h2>
      <div className='custom-form__wrap'>
        <TextFilterInput name='data.nazevZadavatele' label={t('filterProfiles.nazev_zadavatele', 'Název zadavatele')} />

        <TextFilterInput name='data.nazevProfilu' label={t('filterProfiles.nazev_profilu', 'Název profilu')} />
      </div>

      <div className='custom-form__wrap'>
        <TextFilterInput name='data.icoZadavatele' label={t('filterProfiles.ico', 'IČO')} />

        <TextFilterInput name='data.urlProfilu' label={t('filterProfiles.url', 'URL adresa profilu')} />
      </div>
      <div className='custom-form__wrap'>
        <SelectFilterInput
          name='data.formularZneplatnen'
          label={t('filterContracts.formularZneplatnen', 'Stav platnosti')}
          options={optionsZneplatnen}
        />
        <div></div>
      </div>
    </FilterFormWrap>
  )
}

export default FilterProfiles
