import moment from 'moment'
import { SLUG_FORM } from '../api/EformsApi'
import { FORM_TYPES_V2 as FT2 } from '../enums/enumFormTypeV2'
import {
  VARIANTS_TABLE_CONTRACTS_HP,
  VARIANTS_TABLE_NEW_CANCEL_CHANGE,
  VARIANTS_TABLE_PROFILES,
} from '../enums/enumTablesVariants'
import { TABLE_TYPES } from '../enums/enumTableTypes'
import { WORKFLOW_PLACES as WP } from '../enums/enumWorkflowPlaces'
import { FORM_TYPES as FT } from '../enums_v1/enumFormType'
import { DRUH_VZ } from '../enums/enumContractType'
import { DATE_FORMAT_ISO8601 } from './dateFormat'

const PROFIL_STATE = {
  PLATNY: 'PLATNY',
  NEAKTIVNI: 'NEAKTIVNI',
  ZRUSENY: 'ZRUSENY',
}

export const paramsTableType = (contextName: string, variant: string) => {
  const todayPublic = {
    'data.datumUverejneniVvz[gte]': moment().startOf('day').format(DATE_FORMAT_ISO8601),
  }
  switch (contextName) {
    case TABLE_TYPES.contracts:
      return {
        workflowPlace: WP.UVEREJNENO_VVZ,
      }

    case TABLE_TYPES.profiles:
      if (variant === VARIANTS_TABLE_PROFILES.platne) {
        return {
          'data.druhFormulare': FT2.FCZ05,
          'data.stavProfilu': PROFIL_STATE.PLATNY,
          'data.formularZneplatnen': false,
        }
      }
      if (variant === VARIANTS_TABLE_PROFILES.neaktivni) {
        return {
          'data.druhFormulare': FT.CZ05,
          'data.stavProfilu': PROFIL_STATE.NEAKTIVNI,
          'data.formularZneplatnen': false,
        }
      }
      if (variant === VARIANTS_TABLE_PROFILES.zrusene) {
        return {
          'data.druhFormulare': FT.CZ05,
          'data.stavProfilu': PROFIL_STATE.ZRUSENY,
          'data.formularZneplatnen': false,
        }
      }
      return {}

    case TABLE_TYPES.newAll:
      return {
        workflowPlace: WP.UVEREJNENO_VVZ,
        ...todayPublic,
      }

    case TABLE_TYPES.newContracts:
      return {
        workflowPlace: WP.UVEREJNENO_VVZ,
        'data.druhFormulare': [
          FT2.F10,
          FT2.F11,
          FT2.F12,
          FT2.F13,
          FT2.F14,
          FT2.F15,
          FT2.F16,
          FT2.F17,
          FT2.F18,
          FT2.F19,
          FT2.F20,
          FT2.F21,
          FT2.F22,
          FT2.F23,
          FT2.F24,
          FT2.FCZ07,
        ],
        ...todayPublic,
      }

    case TABLE_TYPES.newCancel:
      if (variant === VARIANTS_TABLE_NEW_CANCEL_CHANGE.zruseni) {
        return {
          workflowPlace: WP.UVEREJNENO_VVZ,
          'data.zakazkaZrusena': true,
          ...todayPublic,
        }
      }
      if (variant === VARIANTS_TABLE_NEW_CANCEL_CHANGE.zmeny) {
        return {
          workflowPlace: WP.UVEREJNENO_VVZ,
          form: SLUG_FORM.HLAVNI,
          'data.formularOpravuje': true,
          ...todayPublic,
        }
      }
      return {}

    case TABLE_TYPES.newWins:
      return {
        workflowPlace: WP.UVEREJNENO_VVZ,
        'exists[data.nazevDodavatele]': true,
        'data.druhFormulare': [FT2.F29, FT2.F30, FT2.F31, FT2.F32, FT2.F33, FT2.F34, FT2.F35, FT2.F36, FT2.F37],
        ...todayPublic,
      }

    case TABLE_TYPES.contractsHp:
      let type = {}
      if (variant === VARIANTS_TABLE_CONTRACTS_HP.sluzby) {
        type = { 'data.druhVz': DRUH_VZ.SLUZBY }
      }
      if (variant === VARIANTS_TABLE_CONTRACTS_HP.dodavky) {
        type = { 'data.druhVz': DRUH_VZ.DODAVKY }
      }
      if (variant === VARIANTS_TABLE_CONTRACTS_HP.stavebniprace) {
        type = { 'data.druhVz': DRUH_VZ.PRACE }
      }
      return {
        workflowPlace: WP.UVEREJNENO_VVZ,
        'data.formularZneplatnen': false, // TODO or null
        'data.druhFormulare': [
          FT2.F10,
          FT2.F11,
          FT2.F12,
          FT2.F13,
          FT2.F14,
          FT2.F16,
          FT2.F17,
          FT2.F18,
          FT2.F19,
          FT2.F20,
          FT2.F21,
          FT2.F22,
          FT.F02,
          FT.F05,
          FT.F12,
          FT.F17,
          FT.F21,
          FT.F22,
          FT.F23,
          FT.F24,
          FT.CZ02,
          FT.CZ07,
        ],
        'data.zakazkaZrusena': false,
        'data.lhutaNabidkyZadosti[gte]': moment().startOf('week').format(DATE_FORMAT_ISO8601),
        'data.lhutaNabidkyZadosti[lt]': moment().endOf('week').format(DATE_FORMAT_ISO8601),
        ...type,
      }

    case TABLE_TYPES.stornoContracts:
      return { workflowPlace: WP.STORNOVANO }
  }
  return {}
}
